import React, { Component } from 'react'
import { IonIcon } from '@ionic/react'
import { starOutline } from 'ionicons/icons'
import Basket from '../../lib/basket'
import { withTranslation } from '../../lib/translate'
import { Title, Spacer, BigLabel, NormalText, SmallText } from '../../components/common'

export class Points extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isOpen: false,
			selectedIndex: null
		}
	}

	toggleModal = (val, index) => {
		this.setState({ isOpen: val, selectedIndex: index})
	}

	render() {
		const { __, available_balance } = this.props
		/*const { __, available_balance, rewards } = this.props
		const { isOpen, selectedIndex } = this.state
		let selectedReward = null
		if (isDefined(selectedIndex) && isOpen && rewards){
			selectedReward = rewards[selectedIndex]
		}

		return (
			<div className="loyalty-content">
				<h2 className="points">{ available_balance }</h2>
				<h2>{ __('Points balance') }</h2>
				<IonNote>{ __('Collect points every time you scan and earn') + ' ' + appConfig.general.clientName + ' ' + __('Rewards 1p - 1 point')}</IonNote>
				{(rewards || []).map((reward, index) => {
					return (
						available_balance > reward.stamps_required ?
							<IonCard key={index} onClick={()=> this.toggleModal(true, index) }>
								{isDefined(!reward.reward.image) ?
									<div className="loyalty-image" style={{ backgroundImage: 'url(' + reward.reward.image + ')'}}></div> :
									<div className="loyalty-image" style={{ backgroundImage: 'url(' + require('../../assets/images/no-photo.png') + ')' }}></div>
								}
								<IonCardHeader>
									<IonCardSubtitle>{ __(reward.reward.small_print) }</IonCardSubtitle>
									<IonCardTitle color="secondary">{reward.stamps_required} { __('Points') }</IonCardTitle>
								</IonCardHeader>
								<IonButton expand="block" color="primary">{ __('Redeem') }</IonButton>
							</IonCard>
							:
							<IonCard key={index}>
								<div className="loyalty-image" style={{ backgroundImage: 'url(' + reward.reward.image + ')' }}></div>
								<IonCardHeader>
									<IonCardSubtitle>{ __(reward.reward.small_print) }</IonCardSubtitle>
									<IonCardTitle color="secondary">{reward.stamps_required} { __('Points') }</IonCardTitle>
								</IonCardHeader>
								<IonButton expand="block" color="primary">{ __('Redeem') }</IonButton>
								<div className="cover"><IonIcon icon={ lock } color="white" /></div>
							</IonCard>
					)
				})}
				{isDefined(selectedReward) ?
					<IonModal cssClass='order-intro-modal' isOpen={isOpen} backdrop-dismiss="true" onDidDismiss={() => this.toggleModal(false)}>
						<div className='header'>
							<Header title="Voucher Info" hideBack={ true }/>
						</div>
						{isDefined(!selectedReward.reward.image) ?
							<div className="loyalty-image-01" style={{ backgroundImage: 'url(' + selectedReward.reward.image + ')'}}></div> :
							<div className="loyalty-image-01" style={{ backgroundImage: 'url(' + require('../../assets/images/no-photo.png') + ')' }}></div>
						}
						<div className='modal-text'>
							<h2>{selectedReward.reward.name}</h2>
							<h5>{selectedReward.reward.small_print}</h5>
							<p>{selectedReward.reward.description}</p>
						</div>
						<IonButton expand="block" color="primary" className="default-button modal-button" onClick={() => this.toggleModal(false)}>{ __('Close')}</IonButton>
					</IonModal> : null
				}
			</div>
		)*/
		return (
			<div className="absolute-content points-tab">
				<div className="scrollable-y">
					<Spacer size={ 5 }/>
					<Title>{ __('Loyalty')}</Title>
					<div className="loyalty-badge">
						<div>
							<IonIcon icon={ starOutline }/>
						</div>
					</div>
					<BigLabel className="points">{ available_balance }</BigLabel>
					<NormalText>{ __('Points Balance')}</NormalText>
					<Spacer size={1}/>
					<SmallText>{ __('Worth') }&nbsp;{ Basket._calculatePointsAppliedPrice(available_balance, false, true) }</SmallText>
				</div>
			</div>
		)
	}
}

export default withTranslation(Points)
