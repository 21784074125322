import { GET_TRANSACTION_HISTORY, IMPORT_BASKET, LIST_PAYMENT_CARDS, ADD_PAYMENT_CARD, REMOVE_PAYMENT_CARD, CREATE_ORDER, SET_SCROLL_TOP, ADD_SCANNED_CARD, GET_ORDER_HISTORY, SET_DELIVERY_OPTION, STORE_DELIVERY_ADDRESS, ADD_DELIVERY_ADDRESS, ADD_PICKUP_POINT, STORE_PICKUP_POINT } from './constants'

export const getTransactionHistory = (skipLoading = false) => ({ type: GET_TRANSACTION_HISTORY, skipLoading })

export const importBasket = () => ({ type: IMPORT_BASKET })

export const getPaymentCards = () => ({ type: LIST_PAYMENT_CARDS })

export const addPaymentCard = (name, options = {}) => ({ type: ADD_PAYMENT_CARD, name, options })

export const removePaymentCard = (cardToken, options = {}) => ({ type: REMOVE_PAYMENT_CARD, cardToken, options })

export const createOrder = (paymentType = null) => ({ type: CREATE_ORDER, paymentType })

export const setScrollTop = (value = 0) => ({ type: SET_SCROLL_TOP, value })

export const addScannedCard = (name = '', payment_token = '' , options = {}) => ({ type: ADD_SCANNED_CARD, name, options, payment_token })

export const getOrderHistory = (loading = true) => ({ type: GET_ORDER_HISTORY, loading })

export const setDeliveryOption = (deliveryOption) => ({ type: SET_DELIVERY_OPTION, deliveryOption })

export const storeDeliveryAddress = (deliveryAddress) => ({ type: STORE_DELIVERY_ADDRESS, deliveryAddress })

export const addDeliveryAddress = (deliveryAddress, flag) => ({ type: ADD_DELIVERY_ADDRESS, deliveryAddress, flag })

export const addPickupPoint = (pickUpPoint, code) => ({ type: ADD_PICKUP_POINT, pickUpPoint, code })

export const storePickUpPoint = (pickUpPoint) => ({ type: STORE_PICKUP_POINT, pickUpPoint })
