import React from 'react'
import { withRouter } from 'react-router'
import { IonHeader, IonToolbar, IonMenuButton, IonButtons, IonButton, IonIcon, IonBadge } from '@ionic/react'
import { scan, basket } from 'ionicons/icons'
import { connect } from 'react-redux'
import Basket from '../../lib/basket'
import Icon from '../icon'
import { SmallText } from '../common'
import { forwardTo } from '../../lib/utils'
import { withTranslation } from '../../lib/translate'
import appConfig from '../../appConfig'

import './index.css'

const StaticHeader = ({ __, history }) => {
	const currentPath = history.location.pathname
	const authPages = appConfig.general.authRoutes.indexOf(currentPath) !== -1
	const hideStaticHeader = appConfig.theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1
	const isLogin = currentPath === '/login'

	return (
		<>
			{ hideStaticHeader ? null : appConfig.theme.showHeaderOnAuthRoutes || !authPages ?
				<IonHeader className="static">
					<IonToolbar className="primary-toolbar">
						<IonButtons slot="start">
							{ !authPages ?
								<IonMenuButton />
								:
								<IonButton color='dark' button clear onClick={() => forwardTo(!isLogin ? '/login' : '/register')}>{ __(isLogin ? 'Sign up' : 'Sign in')}</IonButton>
							}
						</IonButtons>
						<IonButtons color="primary">
							<IonButton className='image-button' color="primary-shade" onClick={() => { forwardTo('/dashboard')}}/>
						</IonButtons>
						<IonButtons slot="end">
							{
								Basket.itemsCount() > 0 ?
									<IonButton className='basket-button' onClick={ () => { forwardTo('/order-summary') } } size={ 24 }>
										<div>
											<div className='basket-icon-wrapper'>
												<IonBadge slot="end" color='primary' className={ Basket.itemsCount() >= 10 ? 'badge' : 'badge-small'}>{ Basket.itemsCount() }</IonBadge>
												<Icon icon={ basket } classname='icon' />
											</div>
											<div>
												<SmallText color="gray">
													{ Basket._getTotal(true) }
												</SmallText>
											</div>
										</div>
									</IonButton> :
									!authPages ?
										<IonButton button clear onClick={() => forwardTo('/loyalty', { tab: 'scan' })}><IonIcon color='primary' slot="icon-only" icon={ scan }/></IonButton>
										: null
							}
						</IonButtons>
					</IonToolbar>
					<IonToolbar color="transparent"/>
				</IonHeader>
				: null }
		</>
	)
}

const stateToProps = store => {
	const { orders } = store
	return {
		basketUpdated: orders.basketUpdated
	}
}

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)))
