import { home, trophy, person, mail, pin, fileTrayFull, heart, logIn, logOut, settings } from 'ionicons/icons'
import Dashboard from './screens/dashboard'
import Loyalty from './screens/loyalty'
import Account from './screens/account'
import Feedback from './screens/feedback'
import History from './screens/history'
import HistoryDetails from './screens/historyDetails'
import Locations from './screens/locations'
import Social from './screens/social'
import Login from './screens/login'
import Register from './screens/register'
import ResetPassword from './screens/resetPassword'
import Terms from './screens/terms'
import Privacy from './screens/privacy'
import Faq from './screens/faq'
import Order from './screens/order'
import ItemDetails from './screens/itemDetails'
import ClickAndCollect from './screens/clickAndCollect'
import Cards from './screens/cards'
import CardAdd from './screens/cardAdd'
import OrderSummary from './screens/orderSummary'
import ApplyPoints from './screens/applyPoints'
import Checkout from './screens/checkout'
import OrderCompleted from './screens/orderCompleted'
import ContactDetails from './screens/contactDetails'
import DeliveryOptions from './screens/delivery-options'
import OrderToTable from './screens/orderToTable'
import { getSingleDeliveryOption } from './lib/utils'
import { getConfig } from './appConfig'

const { appType } = getConfig()
const { hasOrdering } = appType

const singleDeliveryOption = getSingleDeliveryOption()

const navConfig = {
	routes: [
		{ label: 'Home', path: '/dashboard', component: Dashboard, icon: home, exact: true, default: true },
		{ label: 'Loyalty', path: '/loyalty', component: Loyalty, icon: trophy, protected: true, state: { tab: 'points' } },
		...hasOrdering ? [{ label: 'Start New Order', path: '/delivery-options', component: DeliveryOptions, icon: settings, protected: false, notInDrawer: !!singleDeliveryOption }] : [],
		...hasOrdering ? [{ label: singleDeliveryOption ? singleDeliveryOption.label : '', path: '/click-and-collect', component: ClickAndCollect, icon: settings, protected: false, notInDrawer: !singleDeliveryOption }] : [],
		// { label: 'Click & Collect', path: '/click-and-collect', component: ClickAndCollect, icon: settings, protected: false },
		{ label: 'Checkout', path: '/checkout', component: Checkout, icon: settings, notInDrawer: true, protected: true },
		{ label: 'Apply Points', path: '/apply-points', component: ApplyPoints, icon: settings, notInDrawer: true, protected: true },
		{ label: 'My Account', path: '/account', component: Account, icon: person, protected: true },
		{ label: 'Leave Feedback', path: '/feedback', component: Feedback, icon: mail, protected: true },
		{ label: 'History', path: '/history', component: History, icon: fileTrayFull, protected: true },
		{ label: 'Restaurants', path: '/locations', component: Locations, icon: pin, protected: false },
		{ label: 'Social Media', path: '/social', component: Social, icon: heart, protected: false },
		{ label: 'Contact Details', path: '/contact-details', component: ContactDetails, protected: true, notInDrawer: true }
	],
	authRoutes: [
		{ label: 'Login', path: '/login', component: Login, icon: logIn, fn: 'login' },
		{ label: 'Logout', path: '/logout', icon: logOut, fn: 'logout' }
	],
	additionalRoutes: [
		{ label: 'Terms & Conditions', path: '/terms', component: Terms },
		{ label: 'Privacy Policy', path: '/privacy', component: Privacy },
		{ label: 'Faq & Support', path: '/faq', component: Faq }
	],
	notInMenuRoutes: [
		{ path: '/register', component: Register },
		{ path: '/reset-password', component: ResetPassword },
		{ path: '/item-details', component: ItemDetails },
		{ path: '/order', component: Order },
		{ path: '/cards', component: Cards },
		{ path: '/card-add', component: CardAdd },
		{ path: '/history-details', component: HistoryDetails },
		{ path: '/order-summary', component: OrderSummary },
		{ path: '/order-completed', component: OrderCompleted },
		{ path: '/contact-details', component: ContactDetails },
		...hasOrdering ? [{ path: '/order-to-table', component: OrderToTable }] : []
	]
}

export default navConfig
