import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import HistoryTab from './historyTab'
import Layout from '../../components/layout'
import { withTranslation } from '../../lib/translate'
import { getTransactionHistory } from '../../store/actions'
import SwipableTabs from '../../components/swipeableTabs'
import appConfig from '../../appConfig'
import './index.css'

const tabLabelMap = {
	ordering: {
		first: 'Loyalty',
		second: 'Orders'
	},
	catalog: {
		first: 'Accrue',
		second: 'Redeem'
	}
}

class History extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	getTransactionHistory = () => {
		this.props.dispatch(getTransactionHistory())
	}

	render() {
		const { __, transactionHistory, orderHistory, restaurants } = this.props

		const { hasOrdering, hasLoyalty } = appConfig.appType
		const tabLabels = hasOrdering ? tabLabelMap.ordering : tabLabelMap.catalog

		const firstTabList = hasOrdering ?
			transactionHistory.map(item => ({ ...item, label: item.stamp_power < 0 ? __('Redeemed') : __('Earned')})) :
			transactionHistory.filter(i => i.stamp_power >= 0).map(item => ({ ...item, label: 'Earned'}))
		const secondTabList = hasOrdering ?
			orderHistory.map(i => ({ label: i.restaurant_name + ' #' + i.id, transaction_date: i.collection_time, item: i })) :
			transactionHistory.filter(i => i.stamp_power < 0).map(item => ({ ...item, label: 'Redeemed' }))

		const firstTabType = 'points'
		const secondTabType = hasOrdering ? 'order' : 'points'

		return (
			<Layout headerTitle={ __('History')} noPadding={ true } color='white' scrollY={false} >
				{hasLoyalty && hasOrdering ?
					<SwipableTabs
						history={ this.props.location }
						tabs={[
							{ label: __(tabLabels.first), tabContent: <HistoryTab restaurants={restaurants} type={ firstTabType } transactionHistory={ firstTabList } getTransactionHistory={ this.getTransactionHistory } /> },
							{ label: __(tabLabels.second), tabContent: <HistoryTab type={ secondTabType } transactionHistory={ secondTabList } getTransactionHistory={ this.getTransactionHistory } /> }
						]}
					/> :
					hasLoyalty ?
						<HistoryTab type={ firstTabType } transactionHistory={ firstTabList } getTransactionHistory={ this.getTransactionHistory } /> :
						hasOrdering ?
							<HistoryTab type={ secondTabType } transactionHistory={ secondTabList } getTransactionHistory={ this.getTransactionHistory } />
							:
							null
				}
			</Layout>
		)
	}
}

const stateToProps = state => {
	const restaurants = {}
	state.restaurants.restaurants.forEach(restaurant => {
		restaurants[restaurant.business_location_id] = restaurant.name
	})
	return {
		transactionHistory: state.orders.history || [],
		orderHistory: state.orders.orderHistory || [],
		restaurants
	}
}

export default withRouter(withTranslation(connect(stateToProps)(History)))
