import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Plugins, Capacitor } from '@capacitor/core'
import { IonMenu, IonHeader, IonToolbar, IonContent, IonList, IonItem, IonMenuToggle, IonButtons, IonButton, IonIcon, IonLabel, IonFooter, IonAlert } from '@ionic/react'
import navConfig from '../../navConfig.js'
import { logout } from '../../store/actions'
import { getDefaultRoute, forwardTo, padNumber } from '../../lib/utils'
import { withTranslation } from '../../lib/translate'
import { Spacer } from '../../components/common'
import './index.css'
import { version as packageJsonVersion } from '../../../package.json'

const { Device } = Plugins
const NavItem = ({ item, hideIcon, handleLogout, className, __ }) => {
	return (
		<IonMenuToggle key={ item.path } auto-hide="false">
			<IonItem className={ 'nav-item' + (className ? ' ' + className : '')} color="primary" button onClick={() => item.fn === 'logout' ? handleLogout() : forwardTo(item.path, item.state)}>
				{ hideIcon ? null : <IonIcon className="nav-icon" slot="start" icon={ item.icon }/> }
				<IonLabel className="nav-label">{__(item.label)}</IonLabel>
			</IonItem>
		</IonMenuToggle>
	)
}

class Drawer extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			appDeviceVersion: '',
			showPopup: false
		}
		this.handleLogout = this.handleLogout.bind(this)
		this.handleModal = this.handleModal.bind(this)
	}

	handleLogout() {
		this.props.dispatch(logout())
		const defaultRoute = getDefaultRoute()
		forwardTo(defaultRoute.path)
	}

	handleModal(val) {
		this.setState({ showPopup: val })
	}

	async componentDidMount () {
		const info = await Device.getInfo()
		const appDeviceVersion = info.appVersion
		this.setState({ appDeviceVersion: appDeviceVersion })
	}

	addZeros = (arr = []) => arr.map((i, index) => {
		// e.g. 1.23.8
		// skip first number (app version) (e.g. 1)
		// add zeros only to patch (e.g. 23) or minor (e.g. 8)
		if (index !== 0) {
			return padNumber(i, 3)
		}
		return i
	})

	isAppVersionValid = (apiVersion = '', appVersion = '') => {
		let ret = true
		if (apiVersion && appVersion && apiVersion !== '' && appVersion !== '') {
			const apiVersionInt = parseInt(this.addZeros(apiVersion.split('.')).join(''), 10)
			const appVersionInt = parseInt(this.addZeros(appVersion.split('.')).join(''), 10)
			ret = appVersionInt >= apiVersionInt
			// eslint-disable-next-line no-console
			console.log(
				'APP VERSION:' +
				'\n    isValid:    ' + ret +
				'\n    platform:   ' + (Capacitor.platform !== 'web' ? 'MOBILE' : 'WEB') +
				'\n    device:     (' + typeof appVersion + ')-> ' + appVersion + ' (int: ' + appVersionInt + ')' +
				'\n    apiversion: (' + typeof apiVersion + ')-> ' + apiVersion + ' (int: ' + apiVersionInt + ')')
		} else {
			// eslint-disable-next-line no-console
			console.error('Skip version checking.')
		}
		return ret
	}

	componentDidUpdate(prevProps){
		const { appDeviceVersion } = this.state

		if (this.props.appVersion !== prevProps.appVersion) {
			if (Capacitor.platform !== 'web') {
				if (!this.isAppVersionValid(this.props.appVersion, appDeviceVersion) && appDeviceVersion !== '') {
					this.handleModal(true)
				}
			} else {
				// web version checking
				if (!this.isAppVersionValid(this.props.appVersion, packageJsonVersion)) {
					this.handleModal(true)
				}
			}
		}
	}

	render (){
		const { auth, __ } = this.props
		const { showPopup, appDeviceVersion } = this.state
		const { loggedIn } = auth

		return (
			<IonMenu className="drawer-menu" side="start" type="overlay" contentId="main">
				<IonHeader>
					<IonToolbar color="primary">
						{/*<IonTitle>{ appConfig.theme.nav.label }</IonTitle>*/}
						<div className="nav-logo"></div>
						<IonButtons slot="end">
							<IonMenuToggle>
								<IonButton button clear>
									<IonIcon slot="icon-only" icon="close"/>
								</IonButton>
							</IonMenuToggle>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent color="primary">
					<IonList lines="none">
						{ navConfig.routes.filter(route => !!route.path && !route.notInDrawer).map(item =>
							<NavItem __={__} key={ item.path } item={ item }/>
						)}
					</IonList>
					<IonList lines="none">
						<NavItem __={__} handleLogout={ this.handleLogout } item={ navConfig.authRoutes.find(item => item.fn === (loggedIn ? 'logout' : 'login'))}/>
					</IonList>
					<Spacer size={ 2 } />
					<IonList lines="none">
						{ navConfig.additionalRoutes.filter(route => !!route.path).map(item =>
							<NavItem className="small-text" __={__} key={ item.path } item={ item } hideIcon={ true }/>
						)}
					</IonList>
					<Spacer size={ 2 } />
				</IonContent>
				<IonFooter className="small-text">
					<IonLabel size="small" slot="start" color="dark">
						v{ Capacitor.platform !== 'web' && appDeviceVersion !== '' ? appDeviceVersion : packageJsonVersion }
					</IonLabel>
					<div className="logo-5l"><div/></div>
				</IonFooter>
				<IonAlert
					isOpen={ showPopup }
					onDidDismiss={ () => this.handleModal(false)}
					header={ __('App version')}
					message={ __('Your app is out of date. Please update.')}
					buttons={[
						{
							text: __('OK'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.handleModal(false)
						}
					]}
				/>
			</IonMenu>
		)
	}
}

const stateToProps = state => {
	const { auth } = state.profile
	const { appVersion } = state.common
	return {
		auth,
		appVersion
	}
}

export default connect(stateToProps)(withRouter(withTranslation(Drawer)))
