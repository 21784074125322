import { Plugins } from '@capacitor/core'

const { Device } = Plugins

export const appConfig = {
	theme: {
		nav: {
			label: 'Woky Ko'
		},
		showHeaderOnAuthRoutes: true,
		showInputIconsOnLogin: false,
		routesWithoutStaticHeader: [ '/item-details' ]
	},
	services: {
		google_maps: {
			google_api_key: 'AIzaSyBvzFfO04bfr0Kp1C67PBZQxmlzUd9DLLM',
			defaultLat: 51.447422,
			defaultLng: -2.596695,
			defaultZoom: 14
		},
		// stripe_key: 'pk_live_AwP170yNwqJ6wOzAC6NYyls0000x1e3y83',
		// stripe_key: 'pk_test_4lYq523b8IJGzFQ4DlljA8sY00H7OO3dQe',
		// stripe_key: 'pk_test_uzpYDbwuWA4RfifpRlyB52VY00FJ1qjdUC', //Olijev
		// stripe_key: 'pk_test_SfLlfP41LhHVIG2vrA95ZrFt', //Kole
		merchantIdentifier: 'merchant.com.wokyko'
	},
	api: {
		baseURL: 'https://woky-ko.herokuapp.com',
		// baseURL: 'https://hybrid-master.herokuapp.com/',
		wordPressBaseURL: 'https://hybridapp.co.uk',
		// baseURL: 'http://192.168.0.150:8000', //preda
		// baseURL: 'https://0eec1a71.ngrok.io', //preda
		paymentURL: 'https://pay-mo.co.uk'
	},
	general: {
		clientName: 'Woky Ko',
		appExitRoutes: [ '/', '/home', '/dashboard' ],
		authRoutes: [ '/login', '/register', '/reset-password' ],
		defaultCurrency: 'gbp',
		default_country_code: 'gb',
		periodForSaga: 20, // x seconds // e.g. fetching profile
		toastDuration: 2000, // 2 seconds
		debug: true,
		redeemPointsMin: 50,
		redeemPointsStep: 50
	},
	envs: {
		APP_DOMAIN: 'woky-ko'
	},
	localization: {
		defaultLocale: 'en',
		supportedLocales: {
			'en': 'English',
			'fr': 'français'
		}
	},
	appType: {
		hasOrdering: true,
		hasLoyalty: true,
		hasEmailValidationEnabled: true
	},
	delivery: [
		{
			id: 'collection',
			label: 'Click & Collect',
			route: '/click-and-collect'
		},
		// {
		// 	id: 'delivery',
		// 	label: 'Order for Delivery',
		// 	route: '/delivery'
		// },
		{
			id: 'table',
			label: 'Order To Table',
			route: '/order-to-table'
		}
		// {
		// 	id: 'pick-up-point',
		// 	label: 'Order to Pick Up Point',
		// 	route: '/pick-up-point'
		// },
		// {
		// 	id: 'charter-delivery',
		// 	label: 'Order for Charter Delivery',
		// 	route: '/delivery'
		// }
	],
	configS3: {
		imageNamePrefix: 'woky_ko_profile_image_',
		bucketName: 'hybrid-apps-profile-images',
		region: 'eu-west-1',
		accessKeyId: 'AKIAIMWCI6M7IF4RTWSA',
		secretAccessKey: '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'
	},
	firebaseConfig: {
		apiKey: 'AIzaSyCGfMydchfUNgGHGbQkSvidMTFFy9lwOD8',
		authDomain: 'woky-ko-110fe.firebaseapp.com',
		databaseURL: 'https://woky-ko-110fe.firebaseio.com',
		projectId: 'woky-ko-110fe',
		storageBucket: 'woky-ko-110fe.appspot.com',
		messagingSenderId: '1080510441604',
		appId: '1:1080510441604:web:f43d2ef33b5ccd71a9fad1',
		measurementId: 'G-8N8VQWQGVM'
	},
	flags: {
		specialInstructions: {
			placeholder: 'example',
			isDisplayed: true
		}
	}
}

Device.getLanguageCode().then(res => {
	const sysLocale = res.value.substr(0,2)
	if (appConfig.localization.supportedLocales[sysLocale]){
		appConfig.localization.defaultLocale = sysLocale
	}
})

export const getConfig = () => appConfig

export default appConfig
