export const GET_TRANSACTION_HISTORY ='GET_TRANSACTION_HISTORY'
export const SET_ORDERS_PROP ='SET_ORDERS_PROP'
export const IMPORT_BASKET ='IMPORT_BASKET'
export const LIST_PAYMENT_CARDS ='LIST_PAYMENT_CARDS'
export const ADD_PAYMENT_CARD ='ADD_PAYMENT_CARD'
export const REMOVE_PAYMENT_CARD ='REMOVE_PAYMENT_CARD'
export const CREATE_ORDER ='CREATE_ORDER'
export const GET_ORDER_HISTORY ='GET_ORDER_HISTORY'
export const SET_SCROLL_TOP = 'SET_SCROLL_TOP'
export const ADD_SCANNED_CARD = 'ADD_SCANNED_CARD'
export const SET_DELIVERY_OPTION = 'SET_DELIVERY_OPTION'
export const STORE_DELIVERY_ADDRESS = 'STORE_DELIVERY_ADDRESS'
export const ADD_DELIVERY_ADDRESS = 'ADD_DELIVERY_ADDRESS'
export const ADD_PICKUP_POINT = 'ADD_PICKUP_POINT'
export const STORE_PICKUP_POINT = 'STORE_PICKUP_POINT'
