import { GET_RESTAURANTS, GET_REWARDS, GET_IKENTOO_MENU, BEFORE_SHOW_TIME_PICKER, BEFORE_CLOSE_TIME_PICKER, GET_RESTAURANTS_SNOOZED_DATA, GET_IKENTOO_MENUS_FOR_LOCATION } from './constants'

export const getRestaurants = () => ({ type: GET_RESTAURANTS })

export const getRewards = () => ({ type: GET_REWARDS })

export const getIkentooMenu= (menuId, businessLocationId, redirect = true) => ({ type: GET_IKENTOO_MENU, menuId, businessLocationId, redirect })

export const beforeShowTimePicker = () => ({ type: BEFORE_SHOW_TIME_PICKER })

export const beforeCloseTimePicker = () => ({ type: BEFORE_CLOSE_TIME_PICKER })

export const getRestaurantSnoozeData = (skipLoading = false) => ({ type: GET_RESTAURANTS_SNOOZED_DATA, skipLoading })

export const getIkentooMenusForLocation = (businessLocationId, additionalData = {}, isDelivery) => ({ type: GET_IKENTOO_MENUS_FOR_LOCATION, businessLocationId, additionalData, isDelivery })