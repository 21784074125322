import React from 'react'
import QRCode from 'qrcode-react'
import ValidateButton from '../../components/validateButton'
import { withTranslation } from '../../lib/translate'
import appConfig from '../../appConfig'
import { isDefined, validateProfileData} from '../../lib/utils'
import { SmallText } from '../../components/common'

const Scan = ({ __, qr_code, profile }) => {
	const valid = validateProfileData(profile).isValid
	const { first_name, last_name } = profile
	return (
		<>
			<div className="loyalty-content scan  scrollable-y">
				<div className="loyalty-title-wrap">
					<h2>{ __(`${first_name} ${last_name}`) }</h2>
					<SmallText className='bold'>{ __('Collect points every time you scan and earn') }</SmallText> <br/>
					<SmallText className='bold'>{ __('(100 points - worth £1.00)') }</SmallText>
				</div>
				{ isDefined(qr_code) ?
					<div className="qr-holder">
						<QRCode value={ qr_code } size={ 200 } />
					</div>
					:
					<div className='noQrCode'><h5>{ __('NO QR CODE') }</h5></div>
				}
				<ValidateButton />
				{!valid && appConfig.appType.hasEmailValidationEnabled ?
					<div className="verified-content">
						<SmallText color='grey'>{__('You can earn, but not redeem points until your account is verified')}</SmallText>
					</div> : null
				}
			</div>
		</>
	)
}

export default withTranslation(Scan)
