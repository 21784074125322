import React from 'react'
import { IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonInput } from '@ionic/react'
import { starOutline, pencilOutline } from 'ionicons/icons'
import Loading from '../../components/spinner'
import { withTranslation } from '../../lib/translate'
import Layout from '../../components/layout'
import { Title, SmallText, Sectiontitle } from '../../components/common'
import './index.css'
import Basket from '../../lib/basket'
//import { makeKey } from '../../lib/utils'
import ValidateButton from '../../components/validateButton'
import appConfig from '../../appConfig'
import Modal from '../../components/modal'
import Incrementer from '../../components/incrementer'
import { connect } from 'react-redux'
import OrderContent from '../../components/orderContent'
import ContentHeader from '../../components/contentHeader'
import { forwardTo, validateProfileData, goBack, go } from '../../lib/utils'


const { changeItemQuantity, itemsCount, setMobile, getMobile, getTotal, getDeliveryOption } = Basket

class OrderSummary extends React.Component {

	constructor(props){
		super(props)
		if (!getMobile() && this.props.profile && this.props.profile.mobile) {
			setMobile(this.props.profile.mobile)
		}
		this.state = {
			quantityModal: null,
			mobile: getMobile() || ''
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.profile.mobile !== prevProps.profile.mobile){
			this.setState({ mobile: this.props.profile.mobile })
		}
	}

	handleInput = (key, val) => {
		this.setState({ [key]: val })
		setMobile(val)
	}

	backHandler = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.fromItemDetails) {
			// skip item details page when we going back from order summary
			go(-2)
		} else {
			goBack()
		}
	}

	handleSubmit = () => {
		const { cards } = this.props

		if (getTotal() === 0) {
			Basket.createOrder()
			return
		}
		if (cards && cards.length >= 1) {
			if (this.props.profile.mobile && this.props.profile.first_name) {
				forwardTo('/checkout')
				setMobile(this.props.profile.mobile )
			} else {
				forwardTo('/contact-details')
			}
		} else {
			if (this.props.profile.mobile && this.props.profile.first_name) {
				setMobile(this.props.profile.mobile )
				forwardTo('/card-add')
			} else {
				forwardTo('/contact-details')
			}
		}
	}

	handleOrderItemClick = (item, index) => {
		this.setState({ quantityModal: { item, index } })
	}

	updateItemQuantity = () => {
		const { item, index } = this.state.quantityModal
		if (this.state.quantityModal) {
			changeItemQuantity(index, item.quantity)
			this.setState({ quantityModal: null })
		}
	}

	onIncrementerUpdate = newQuantity => {
		this.setState({ quantityModal: {
			...this.state.quantityModal,
			item: {
				...this.state.quantityModal,
				quantity: newQuantity
			}
		} })
	}

	render (){
		const { __, profile } = this.props
		const { quantityModal, mobile } = this.state
		const valid = validateProfileData(profile).isValid
		const labelTitle = Basket.getOrderType() === 'Click & Collect' ? 'Click & Collect Order' : Basket.getOrderType()
		return (
			<Loading>
				<Layout headerTitle={ __('Order Summary') } backHandler = { this.backHandler }>
					<div className="absolute-content flex-row-wrapper">
						<div className="scrollable-y checkout">
							{/* <Title>{ __('Order Summary')}</Title> */}
							{/* <SmallText color="gray">
								{ __('Ordering for') } <strong>{ getOrderDate() }</strong> @ { getOrderTime() }<br />
								{ __('from') } <strong>{ getRestauranName() }</strong>
							</SmallText> */}
							{
								Basket.getItems().length > 0 ?
									<ContentHeader __={ __ } deliveryOption={ getDeliveryOption() } orderType={ labelTitle } />
									: null
							}
							<IonItem className='contact-number' lines='full'>
								<IonLabel slot='start' className="ion-text-wrap">
									<Sectiontitle className="no-margin">{__('Contact number')}</Sectiontitle>
								</IonLabel>
								<IonInput onIonChange={ e => this.handleInput('mobile', e.target.value) } clearInput required={ true } type="tel" pattern="tel" inputmode="tel" value={ mobile }>
								</IonInput>
								<IonButtons slot='end' className='no-margin'>
									<IonButton color="gray" /*disabled={ value === '' }*/ fill="clear" size="small" /*onTouchStart={() => this.togglePass(true)} onTouchEnd={() => this.togglePass(false)} onMouseDown={() => this.togglePass(true)} onMouseUp={() => this.togglePass(false)}*/>
										<IonIcon slot="icon-only" size='small' icon={ pencilOutline }/>
									</IonButton>
								</IonButtons>
							</IonItem>
							<div className="righted">
								<IonButton fill="clear" className="link" onClick={() => forwardTo('/order')}><strong>+ { __('Add Items') }</strong></IonButton>
							</div>
							{ itemsCount() > 0 ?
								<OrderContent handleOrderItemClick={ this.handleOrderItemClick.bind(this) }/> :
								<IonItem lines="none">
									<div className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>{ __('No items') }</div>
								</IonItem>

							}
							<div className='validate-content'>
								<ValidateButton />
								{!valid && appConfig.appType.hasEmailValidationEnabled ?
									<div className="verified-content">
										<SmallText color='grey'>{__('You can earn, but not redeem points until your account is verified')}</SmallText>
									</div> : null
								}
							</div>
						</div>
						<div className="flex-min">
							<IonButton disabled={ itemsCount() === 0 || !valid } onClick={ () => forwardTo('/apply-points') } expand="block" fill="outline" color="gray">
								<IonIcon slot="start" icon={ starOutline } />
								{ __('Reedem Points') }
							</IonButton>
							<IonButton disabled={ itemsCount() === 0 } onClick={ this.handleSubmit } expand="block">{ __('Checkout') }</IonButton>
						</div>
					</div>

					<Modal
						title={ __('Change quantity') }
						action={ this.updateItemQuantity }
						actionLabel={ __('Change')}
						isOpen={ !!quantityModal }
						onDidDismiss={() => this.setState({ quantityModal: null })}>
						{ quantityModal && quantityModal.item ?
							<Incrementer allowNegative={ false } quantity={ quantityModal.item.quantity } onUpdate={ this.onIncrementerUpdate }/>
							: null }
					</Modal>
				</Layout>
			</Loading>
		)
	}
}

const mapStateToProps = store => {
	return {
		basketUpdated: store.orders.basketUpdated,
		profile: store.profile.profile,
		cards: store.orders.cards || []
	}
}

export default connect(mapStateToProps)(withTranslation(OrderSummary))
