import { SET_ORDERS_PROP, SET_SCROLL_TOP, SET_DELIVERY_OPTION} from './constants'
import { isDefined } from '../../lib/utils'

let initialState = {
	history: [],
	basketUpdated: null,
	cards: [],
	orderHistory: [],
	scrollTop: 0,
	deliveryOption: null
}

function reducer(state = initialState, action) {
	switch (action.type) {
	case SET_ORDERS_PROP:
		return { ...state, [action.key]: isDefined(action.merge) && action.merge ? {...state[action.key], ...action.value} : action.value }
	case SET_SCROLL_TOP:
		return { ...state, scrollTop: action.value }
	case SET_DELIVERY_OPTION:
		return { ...state, deliveryOption: action.deliveryOption }
	default:
		return state
	}
}

export default reducer
